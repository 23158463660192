body {
  width: 100%;
  min-height: 100vh;
  margin: 0;

  background: url("~/assets/img/login_bg.png") no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1000;
  opacity: 1.0
}

label, .label {
  line-height: 40px;
}

.el-card {
  margin: 32px 0;
  /*padding: 50px;*/
}

.el-card>.el-card__header {
  padding: 30px 30px 18px;
}

.el-card>.el-card__body {
  padding: 24px 30px;
}

.el-card>.el-card__header+.el-card__body {
  padding: 18px 30px 30px;
}

.el-table__header>thead>tr.menu-table-header-row>th,
.el-table__header>thead>tr.permission-table-header-row>th,
.el-table__header>thead>tr.role-table-header-row>th {
  background-color: rgba(192, 196, 204, 0.08);
}

.el-aside {
  /*overflow: hidden;*/
  overflow: auto;
  background-color: rgb(0, 21, 41);
}

/*.el-menu-vertical-demo {
  min-height: 400px;
}*/

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 300px;
}

.el-table__empty-block {
  max-height: 200px;
}

.el-dialog {
  border: 0.6em solid #3a8ee6;
}

.el-dialog__footer {
  padding-bottom: 50px;
}

.el-dialog__footer>button[type=submit] {
  width: 60%;
}

.el-table__row>.el-table__expand-column.el-table__cell>.cell {
  display: none;
}

.el-table__cell.el-table__expanded-cell {
  padding-bottom: 3em;
  padding-left: 2em;
  padding-right: 2em;
  background-color: lightgray;
}

.el-table__cell.el-table__expanded-cell>.el-descriptions>.el-descriptions__body {
  background-color: inherit;
}
.el-table__cell.el-table__expanded-cell>.el-descriptions>.el-descriptions__body .el-descriptions-row {
  background-color: inherit;
}

.el-table__cell.el-table__expanded-cell>.el-descriptions>.el-descriptions__body .el-descriptions-row .el-descriptions-item__label:not(.is-bordered-label) {
  width: 7em;
  line-height: 32px;
}

.el-table__cell.el-table__expanded-cell>.el-descriptions>.el-descriptions__body .el-descriptions-row .el-descriptions-item__content {
  line-height: 32px;
}

.el-transfer>.el-transfer-panel>.el-transfer-panel__header,
.el-transfer>.el-transfer-panel>.el-transfer-panel__header>label.el-checkbox,
.el-transfer>.el-transfer-panel>.el-transfer-panel__header>label.el-checkbox>.el-checkbox__label {
  line-height: 40px;
}


.el-transfer>.el-transfer-panel>.el-transfer-panel__header>label.el-checkbox>.el-checkbox__label {
  font-size: 14px;
}

.el-message-box.sign-in-box {
  padding:  40px;
}

.el-message-box.sign-in-box>.el-message-box__header {
  padding-bottom:  30px;
  text-align: center;

  background: url(~/assets/img/logo.png) no-repeat;
  background-size: 25px;
  background-position-y: 13px;
  background-position-x: 36px;

}

.el-message-box.sign-in-box>.el-message-box__header>.el-message-box__title {
  padding-left: 24px;
  font-size: 20px;
}

.el-message-box__wrapper {
  z-index: 999999999 !important;
}

div#app {
  background-color: #fff;
}

.el-container>.el-main>.el-breadcrumb {
  position: sticky;
  top: -20px;
  height: 60px;
  line-height: 60px;
  background: rgb(247, 248, 250);
  z-index: 999;
  margin-top: -23px;
  margin-bottom: -23px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}

